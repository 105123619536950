.logo{
  transform: scale(5);
  margin-top: 20px ;
  margin-left: 90px;
  width: 50%
}

@media all and (max-width: 1000px) {
  .navbar-right{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .navBar-responsive .logo{
    text-align: center;
    width: 100vw;
    margin-left: 0;
  }
}

@media all and (max-width: 500px) {
.navBar-responsive .logo{
  transform: scale(4);
  margin-top: 10px ;
  margin-left: 50px;
 width: 50%;
 text-align:left;;

}
.navbar-right{
  display: block;
  overflow-y:  none;
  margin-top: 0px;
  
}
}